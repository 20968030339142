import { gql } from '@apollo/client';

export const GET_FREE_TEXT_QUERY = gql`
  query QueryFreeText($input: FreeTextInput!) {
    queryFreeTextNew(input: $input) {
      icd {
        code
        description
      }
      cpt {
        code
        description
      }
      nucc {
        id
        description
      }
    }
  }
`;

export const SEND_OUTCOME_QUERY = gql`
  mutation searchCareOutcome($input: SearchCareOutcomeInput!) {
    searchCareOutcome(input: $input)
  }
`;

export const FIND_QUERY = gql`
  query find($input: FindInput!) {
    find(input: $input) {
      results {
        providerType
        npi
        firstName
        lastName
        name
        specialties {
          id
          description
          isPrimary
        }
        preferredProvider
        locations {
          providerLocationId
          addressLine1
          addressLine2
          city
          state
          zip
          distance
          officeName
          phoneNumber {
            number
          }
          fax {
            number
          }
          performance {
            code
            description
          }
          costEstimation {
            description
            min
            max
          }
          highValuePerformance
          acceptNewPatients
          implementationSpecificFields
        }
        implementationSpecificFields
      }
      alternativeResults {
        providerType
        npi
        firstName
        lastName
        name
        specialties {
          id
          description
        }
        locations {
          addressLine1
          addressLine2
          city
          state
          zip
          distance
          officeName
          phoneNumber {
            number
          }
          fax {
            number
          }
          performance {
            code
            description
          }
          costEstimation {
            description
            min
            max
          }
          acceptNewPatients
          implementationSpecificFields
        }
        implementationSpecificFields
      }
      pagination {
        hasNextPage
        pageNumber
        totalPages
      }
      implementationSpecificFields
    }
  }
`;

export const PREFERRED_PROVIDERS_QUERY = gql`
  query getPreferredProviders($input: FindInput!) {
    getPreferredProviders(input: $input) {
      organizationHasPreferredProviders
      results {
        providerType
        npi
        firstName
        lastName
        name
        specialties {
          id
          description
          isPrimary
        }
        preferredProvider
        locations {
          providerLocationId
          addressLine1
          addressLine2
          city
          state
          zip
          distance
          officeName
          phoneNumber {
            number
          }
          fax {
            number
          }
          performance {
            code
            description
          }
          costEstimation {
            description
            min
            max
          }
          highValuePerformance
          acceptNewPatients
          implementationSpecificFields
        }
        implementationSpecificFields
      }
    }
  }
`;

export const LOOKUP_QUERY = gql`
  query lookup($input: LookupMemberInput!) {
    lookup(input: $input) {
      memberToken
    }
  }
`;

export const GET_MEMBER_TOKEN_QUERY = gql`
  query getMemberToken($input: PatientDetailsInput!) {
    getMemberToken(input: $input) {
      memberToken
    }
  }
`;

export const GET_INSURERS = gql`
  query getInsurers {
    getInsurers
  }
`;

export const GET_PLANS_BY_INSURER = gql`
  query getPlansByInsurer($insurer: String!) {
    getPlansByInsurer(insurer: $insurer)
  }
`;

export const GET_MATCHED_ELIGIBILITY_PLAN = gql`
  query getMatchedEligibilityPlan($memberToken: String, $sourceVaultToken: String) {
    getMatchedEligibilityPlan(memberToken: $memberToken, sourceVaultToken: $sourceVaultToken) {
      insuranceDetails {
        referralRequestRequired
      }
    }
  }
`;

export const REQUEST_REFERRAL = gql`
  mutation requestReferral($input: RequestReferralInput!) {
    requestReferral(input: $input) {
      status
      procedures {
        status
        statusCode
        serviceQuantity
        quantityType
        cpt {
          code
          description
        }
        statusReasons {
          value
        }
        notes {
          message
        }
        startDate
        expirationDate
      }
      reason
      certificationNumber
      certificationEffectiveDate
      certificationExpirationDate
      fromDate
      toDate
    }
  }
`;

export const REQUEST_REFERRAL_NEW = gql`
  mutation requestReferral($input: RequestReferralInput!) {
    requestReferral(input: $input) {
      status
      procedures {
        status
        statusCode
        serviceQuantity
        quantityType
        cpt {
          code
          description
        }
        statusReasons {
          value
        }
        notes {
          message
        }
        startDate
        expirationDate
      }
      certificationNumber
      reason
      certificationEffectiveDate
      certificationExpirationDate
      serviceInformation {
        fromDate
        toDate
        serviceLevel
        quantity
        quantityType
      }
      memberInformation {
        memberId
        subscriberName {
          firstName
          lastName
        }
        subscriberRelationship
      }
    }
  }
`;

export const GET_APP_CONFIG = gql`
  query getAppConfig {
    getAppConfig {
      googleApiKey
    }
  }
`;

export const GET_APP_CONFIG_FOR_SDK = gql`
  query getAppConfig {
    getAppConfig {
      googleApiKey
      contentSources {
        dataSourceEnum
        isInsurer
      }
      vimSpecialtiesMap {
        specialtyName
        vimSpecialty {
          description
          bestVimSpecId
        }
      }
    }
  }
`;

export const GET_GEO = gql`
  query getGeo($input: AddressInput!) {
    getGeo(input: $input) {
      addressLine1
      city
      state
      zip
    }
  }
`;

export const CREATE_PDF = gql`
  mutation CreatePdfMutation($input: CreatePdfInput!) {
    createPdf(input: $input) {
      encoded_string
    }
  }
`;

export const CREATE_REFERRAL_REQUEST_PDF = gql`
  mutation CreateReferralRequestPdfMutation($input: CreateReferralRequestPdfInput!) {
    createReferralRequestPdf(input: $input) {
      encoded_string
    }
  }
`;

export const GET_CPTS_BY_CODES_QUERY = gql`
  query getCptsByCodes($input: CptCodesInput!) {
    getCptsByCodes(input: $input) {
      code
      description
    }
  }
`;

export const SUPPORTED_PERSON_CAPABILITY_QUERY = gql`
  query SupportedPersonCapability($input: SupportedPersonCapabilityInput!) {
    supportedPersonCapability(input: $input)
  }
`;
