import { isEqual } from 'lodash-es';
import {
  AddressWithDistanceAndPhone,
  FacilityOutcomeInput,
  FormDataSpecialty,
  Location,
  OrderAssistOption,
  OutcomeInput,
  ProviderOutcomeInput,
  ProviderType,
  SelectOption,
  SendOutcomeInput,
} from '../../types';

import { internalApi } from '../../../../internal-api';
import { orderAssistLogger } from '../../logger';
import { isFacilityResult, isProviderResult } from '../../utils/general';

export interface SendOutcomeProps {
  item: OrderAssistOption;
  isProvider: boolean;
  isAlternative: boolean;
  cpts?: SelectOption[];
  specialty: FormDataSpecialty;
  location: AddressWithDistanceAndPhone;
  extraInfo?: { distanceInfo?: string };
}

const mapAddressToLocationInput = (
  address: AddressWithDistanceAndPhone,
): Omit<Location, 'geo' | 'address'> => {
  return {
    distance: address.distance,
    addressLine1: address.address1 ?? '',
    city: address.city ?? '',
    state: address.state ?? '',
    phoneNumber: {
      number: address.phone ?? '',
      countryDialingCode: '+1',
    },
    zip: address.zipCode ?? '',
  };
};

const formatOutcomeInput = ({
  cpts,
  isAlternative,
  item,
  location,
  specialty,
  extraInfo,
}: SendOutcomeProps): OutcomeInput | undefined => {
  const isProvider = isProviderResult(item);
  const isFacility = isFacilityResult(item);

  const additionalAddresses = item.locations.filter((address) => !isEqual(address, location));

  const baseInput: OutcomeInput = {
    additionalAddresses: additionalAddresses?.map((address) => mapAddressToLocationInput(address)),
    cpts: cpts?.map(({ value }) => value),
    isAlternativeResult: isAlternative,
    mpin: item.details.mpin,
    npi: item.details.npi,
    tin: item.details.tin,
    tier: item.extraInfo?.tier,
    tierType: item.extraInfo?.tierType,
    providerGroupIndicator: item.extraInfo?.providerGroupIndicator,
    specialty: {
      id: item.details.specialties[0].id,
      description: specialty.searchDisplay,
      isPrimary: item.details.specialties[0].isPrimary,
    },
    location: mapAddressToLocationInput(location),
    distanceInfo: extraInfo?.distanceInfo,
    providerType: isProvider ? ProviderType.PROVIDER : ProviderType.FACILITY,
    locationName: isProvider
      ? `${item.details.complexName.firstName} ${item.details.complexName.lastName}`
      : item.details.name,
  };

  if (isProvider) {
    return {
      ...baseInput,
      targetProvider: {
        firstName: item.details.complexName.firstName,
        lastName: item.details.complexName.lastName,
      },
      acceptingNewPatients: item.extraInfo?.acceptingNewPatients,
      premiumDesignationText: item.extraInfo?.premiumDesignationText,
      premiumDesignationLong: item.extraInfo?.premiumDesignationLong,
    } as ProviderOutcomeInput;
  }
  if (isFacility) {
    return {
      ...baseInput,
      organizationTypeDescription: item.extraInfo?.organizationTypeDescription,
      freeStandingFacility: item.extraInfo?.freeStandingFacility,
      preferredLab: item.extraInfo?.preferredLab,
      plnDdpCd: item.extraInfo?.plnDdpCd,
    } as FacilityOutcomeInput;
  }
};

export const sendOutcome = async (
  props: SendOutcomeProps,
  token: string,
  shouldUseSourceVaultTokens: boolean,
): Promise<void> => {
  const sendOutcomeInput = formatOutcomeInput(props);
  if (!sendOutcomeInput) {
    return orderAssistLogger.error('OutcomeInput is from an unknown type', { item: props.item });
  }
  const payload: SendOutcomeInput = {
    ...(shouldUseSourceVaultTokens
      ? { anonymizedPatient: token }
      : { encryptedPatientDetails: { encryptedIdentifyInput: token } }),
    outcomeInput: sendOutcomeInput,
  };
  orderAssistLogger.info('Sending outcome to poca...', {
    payload,
  });
  try {
    await internalApi.orderAssistBff.sendOutcome(payload);
    orderAssistLogger.info('Sent an outcome to POCA');
  } catch (error) {
    orderAssistLogger.error('Failed to send an outcome to POCA', {
      error,
      payload,
    });
  }
};
