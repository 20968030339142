import { PatientDetailsInput } from '@getvim/platform-types';
import { SearchMethod } from '../../analytics/types';
import {
  ReferralRequestPDFInput,
  ReferralRequestResponse,
  RequestReferralInput,
} from '../../components/app/components/referral-request/types';
import { orderAssistLogger } from '../../components/app/logger';
import {
  Address,
  AppConfig,
  EncodedPDFFile,
  FindQuery,
  FindResponse,
  FreeTextQueryInput,
  Geo,
  GetCptsByCodesInput,
  GetMemberTokenResult,
  GetPreferredProvidersResponse,
  OrderAssistResultsPDFInput,
  QueryFreeTextResult,
  SendOutcomeInput,
  SupportPersonCapabilityInput,
  WidgetSource,
} from '../../components/app/types';
import { LookupInput, LookupResult } from '../../components/app/types/lookup';

import {
  CREATE_PDF,
  CREATE_REFERRAL_REQUEST_PDF,
  FIND_QUERY,
  GET_APP_CONFIG,
  GET_CPTS_BY_CODES_QUERY,
  GET_FREE_TEXT_QUERY,
  GET_GEO,
  GET_INSURERS,
  GET_MATCHED_ELIGIBILITY_PLAN,
  GET_MEMBER_TOKEN_QUERY,
  GET_PLANS_BY_INSURER,
  LOOKUP_QUERY,
  PREFERRED_PROVIDERS_QUERY,
  REQUEST_REFERRAL,
  REQUEST_REFERRAL_NEW,
  SEND_OUTCOME_QUERY,
  SUPPORTED_PERSON_CAPABILITY_QUERY,
} from './queries';
import { BaseInternalApi } from '../baseInternalApi';
import { featureFlagsClientOrderOptimization } from '../../services/featureFlags';

export class OrderAssistBffApi extends BaseInternalApi {
  constructor(accessToken?: string) {
    super('/api/graphql', accessToken);
  }

  public async find(
    input: FindQuery,
    method: SearchMethod,
    source: WidgetSource | undefined,
  ): Promise<FindResponse> {
    const gqlClient = await this.getClient();
    const result = await gqlClient.query<{ find: FindResponse }, { input: FindQuery }>({
      query: FIND_QUERY,
      variables: { input },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'x-source': source,
          'x-method': method,
        },
      },
    });
    return result.data?.find;
  }

  public async supportedPersonCapabilities(
    input: SupportPersonCapabilityInput,
    source: WidgetSource | undefined,
  ): Promise<boolean> {
    const gqlClient = await this.getClient();
    const result = await gqlClient.query<
      { supportedPersonCapability: boolean },
      { input: SupportPersonCapabilityInput }
    >({
      query: SUPPORTED_PERSON_CAPABILITY_QUERY,
      variables: { input },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'x-source': source,
        },
      },
    });
    return result.data?.supportedPersonCapability;
  }

  public async freeText(
    input: FreeTextQueryInput,
    source?: WidgetSource,
  ): Promise<QueryFreeTextResult> {
    const gqlClient = await this.getClient();
    const result = await gqlClient.query({
      query: GET_FREE_TEXT_QUERY,
      variables: { input },
      context: {
        headers: {
          ...(source && { 'x-source': source }),
        },
      },
    });
    return result.data?.queryFreeTextNew;
  }

  public async sendOutcome(input: SendOutcomeInput): Promise<void> {
    const gqlClient = await this.getClient();
    await gqlClient.mutate({
      mutation: SEND_OUTCOME_QUERY,
      variables: { input },
    });
  }

  public async getPreferredProviders(
    input: FindQuery,
    method: SearchMethod,
    source: WidgetSource | undefined,
  ): Promise<GetPreferredProvidersResponse> {
    const gqlClient = await this.getClient();

    const result = await gqlClient.query<
      { getPreferredProviders: GetPreferredProvidersResponse },
      { input: FindQuery }
    >({
      query: PREFERRED_PROVIDERS_QUERY,
      variables: { input },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'x-source': source,
          'x-method': method,
        },
      },
    });
    return result.data?.getPreferredProviders;
  }

  public async getMemberToken(
    input: PatientDetailsInput,
    source: WidgetSource,
  ): Promise<GetMemberTokenResult> {
    const gqlClient = await this.getClient();
    const result = await gqlClient.query({
      query: GET_MEMBER_TOKEN_QUERY,
      variables: { input },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'x-source': source,
        },
      },
    });

    return result.data.getMemberToken;
  }

  public async lookup(input: LookupInput, source: WidgetSource): Promise<LookupResult> {
    const gqlClient = await this.getClient();
    const result = await gqlClient.query({
      query: LOOKUP_QUERY,
      variables: { input },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'x-source': source,
        },
      },
    });

    return result.data.lookup;
  }

  public async getInsurers(source: WidgetSource): Promise<string[]> {
    const gqlClient = await this.getClient();
    const result = await gqlClient.query({
      query: GET_INSURERS,
      fetchPolicy: 'cache-first',
      context: {
        headers: {
          'x-source': source,
        },
      },
    });

    return result.data.getInsurers;
  }

  public async getPlansByInsurer(insurer: string, source: WidgetSource): Promise<string[]> {
    const gqlClient = await this.getClient();
    const result = await gqlClient.query({
      query: GET_PLANS_BY_INSURER,
      variables: { insurer },
      fetchPolicy: 'cache-first',
      context: {
        headers: {
          'x-source': source,
        },
      },
    });

    return result.data.getPlansByInsurer;
  }

  public async getMatchedEligibilityPlan(
    token: string,
    source: WidgetSource,
    shouldUseSourceVaultTokens: boolean,
  ): Promise<boolean> {
    try {
      const gqlClient = await this.getClient();
      const result = await gqlClient.query({
        query: GET_MATCHED_ELIGIBILITY_PLAN,
        variables: {
          memberToken: shouldUseSourceVaultTokens ? undefined : token,
          sourceVaultToken: shouldUseSourceVaultTokens ? token : undefined,
        },
        fetchPolicy: 'cache-first',
        context: {
          headers: {
            'x-source': source,
          },
        },
      });
      orderAssistLogger.info('Got getMatchedEligibilityPlan response', {
        token,
        source,
        result,
        shouldUseSourceVaultTokens,
      });

      return result?.data?.getMatchedEligibilityPlan?.insuranceDetails?.referralRequestRequired;
    } catch (error) {
      orderAssistLogger.error('getMatchedEligibilityPlan failed', {
        token,
        source,
        shouldUseSourceVaultTokens,
        error,
      });
      return false;
    }
  }

  public async requestReferral(
    input: RequestReferralInput,
    source: WidgetSource,
  ): Promise<ReferralRequestResponse> {
    try {
      const useReferralRequestNewFields = await featureFlagsClientOrderOptimization.getFlag({
        flagName: 'useReferralRequestNewFields',
        defaultValue: false,
      });
      const gqlClient = await this.getClient();
      const result = await gqlClient.mutate({
        mutation: useReferralRequestNewFields ? REQUEST_REFERRAL_NEW : REQUEST_REFERRAL,
        variables: { input },
        context: {
          headers: {
            'x-source': source,
          },
        },
      });
      orderAssistLogger.info('Got requestReferral response', { input, source, result });

      return result?.data?.requestReferral;
    } catch (error) {
      orderAssistLogger.error('requestReferral failed', { input, source, error });
      throw error;
    }
  }

  public async getAppConfig(): Promise<AppConfig> {
    const gqlClient = await this.getClient();
    const result = await gqlClient.query({
      query: GET_APP_CONFIG,
      fetchPolicy: 'cache-first',
    });

    return result.data.getAppConfig;
  }

  public async getGeo(
    input: Required<Pick<Geo, 'city' | 'state' | 'addressLine1'>>,
  ): Promise<Address> {
    try {
      const gqlClient = await this.getClient();
      const result = await gqlClient.query({
        query: GET_GEO,
        variables: { input },
      });
      orderAssistLogger.info('Got getGeo response', { input, result });

      const { addressLine1, city, state, zip } = result.data.getGeo;
      return { address1: addressLine1, city, state, zipCode: zip };
    } catch (error) {
      orderAssistLogger.error('getGeo failed', { input, error });
      throw error;
    }
  }

  public async createResultsPdf(
    input: OrderAssistResultsPDFInput,
  ): Promise<EncodedPDFFile | undefined> {
    try {
      const gqlClient = await this.getClient();
      const result = await gqlClient.mutate({
        mutation: CREATE_PDF,
        variables: { input },
      });
      return result.data.createPdf;
    } catch (error) {
      orderAssistLogger.error('createPDF failed', { input, error });
      return undefined;
    }
  }

  public async createReferralRequestPdf(
    input: ReferralRequestPDFInput,
    source: WidgetSource,
  ): Promise<EncodedPDFFile | undefined> {
    try {
      const gqlClient = await this.getClient();
      const result = await gqlClient.mutate({
        mutation: CREATE_REFERRAL_REQUEST_PDF,
        variables: { input },
        context: {
          headers: {
            'x-source': source,
          },
        },
      });
      return result.data.createReferralRequestPdf;
    } catch (error) {
      orderAssistLogger.error('createReferralRequestPdf failed', { input, error });
      return undefined;
    }
  }

  public async getCptsByCodes(input: GetCptsByCodesInput): Promise<
    {
      code: string;
      description: string;
    }[]
  > {
    try {
      const gqlClient = await this.getClient();
      const result = await gqlClient.query({
        query: GET_CPTS_BY_CODES_QUERY,
        variables: { input },
      });
      return result.data.getCptsByCodes;
    } catch (error) {
      orderAssistLogger.error('Failed to getCptsByCodes', { input, error });
      throw error;
    }
  }
}
