import { isEmpty } from 'lodash-es';
import { SearchMethod } from '../../../../../analytics/types';
import { internalApi } from '../../../../../internal-api';
import { RESULTS_PER_PAGE } from '../../../consts/resultsPerPage';
import { orderAssistLogger } from '../../../logger';
import {
  FindQuery,
  FindResponse,
  GetOrderAssistInput,
  GetOrderAssistResult,
  UHC,
  WidgetSource,
} from '../../../types';

import { getFindQuery, getResults } from './formatters';

const responseFormatter = (
  findInput: FindQuery,
  findResponse: FindResponse,
): GetOrderAssistResult => {
  const { results, alternativeResults, implementationSpecificFields, pagination } = findResponse;
  const responseSpecificFields = implementationSpecificFields as UHC.ResponseSpecificFields;
  const { benefits, disclaimers, providerCacheId, isCostAvailable, distanceInfo } =
    responseSpecificFields || {};
  const basicRankStarter = (pagination.pageNumber - 1) * RESULTS_PER_PAGE;
  const rankStarters = {
    results: basicRankStarter,
    alternativeResults: basicRankStarter + results.length,
  };

  return {
    searchResults: getResults({ results, rankStarter: rankStarters.results }),
    alternativeResults: getResults({
      results: alternativeResults,
      rankStarter: rankStarters.alternativeResults,
    }),
    disclaimers,
    pagination,
    providerCacheId,
    distanceInfo,
    costAvailable: isCostAvailable,
    benefits: isEmpty(benefits) ? undefined : benefits,
    isOutOfNetworkResults:
      !findInput.searchParams?.encryptedIdentifyInput &&
      !findInput.searchParams?.anonymizedPatient &&
      !findInput.filters?.memberPlan,
  };
};

export const find = async (
  input: GetOrderAssistInput & { shouldUseSourceVaultTokens: boolean },
  method: SearchMethod,
  source: WidgetSource | undefined,
): Promise<GetOrderAssistResult | undefined> => {
  try {
    const findQuery = getFindQuery(input);

    const findResponse: FindResponse = await internalApi.orderAssistBff.find(
      findQuery,
      method,
      source,
    );

    const orderAssistResult = responseFormatter(findQuery, findResponse);
    orderAssistLogger.info('Order Assist find query result', { orderAssistResult });
    return orderAssistResult;
  } catch (error) {
    orderAssistLogger.error('Failed to find', { error });
    return undefined;
  }
};
