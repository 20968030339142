import { StandardEvents } from '@getvim/vim-connect';
import { UserConfig } from '../../../../hooks';
import { WidgetSource, AddressWithFull, Patient, WriteBackProvider } from '../../types';

import { StepProps } from './stepper/step/Step';
import { OnFreeTextQueryMethod } from '../order-assist-app/hooks';

export interface ReferralFlowManagerProps {
  backToResults: () => void;
  onFreeTextQuery: OnFreeTextQueryMethod;
  onResponse: (value: Partial<ReferralRequestExportContext>) => void;
  targetProvider: WriteBackProvider;
  memberToken?: string;
  sourceVaultToken?: string;
  source?: WidgetSource;
  requestingProvider?: RequestingProvider;
  serviceInformation?: ServiceInformation;
  shouldUpdateAuthCode: boolean;
  organizationId: number | undefined;
}

export enum ReferralStep {
  REFERRAL_REQUIRED = 'REFERRAL_REQUIRED',
  REQUESTING_PROVIDER = 'REQUESTING_PROVIDER',
  SERVICE_INFORMATION = 'SERVICE_INFORMATION',
  LOADING = 'LOADING',
  RESULT = 'RESULT',
}

export interface ReferralContext {
  stepStatus: {
    [ReferralStep.REQUESTING_PROVIDER]: StepProps;
    [ReferralStep.SERVICE_INFORMATION]: StepProps;
  };
  targetProvider: ProviderInput;
  requestingProvider?: RequestingProvider;
  memberToken: string;
  serviceInformation?: ServiceInformation;
  response: ReferralRequestResponse;
  requestSubmissionTime?: number;
  isLoading: boolean;
  updateCodeStatus: UpdateCodeStatus;
}

export interface ContextOptions {
  context: ReferralContext;
  updateContext: (enhanced: ReferralContext) => void;
}

export type ReferralSteps = typeof ReferralStep;

export enum ReferralRequestStatus {
  APPROVED = 'approved',
  APPROVED_AND_MODIFIED = 'approved_and_modified',
  PENDING = 'pending',
  CANCELLED = 'cancelled',
  NO_ACTION_REQUIRED = 'no_action_required',
  NO_ANSWER = 'no_answer',
  ERROR = 'error',
}

export interface RequestingProvider {
  npi?: string;
  firstName?: string;
  lastName?: string;
  specialty?: StandardEvents.TransformedSpecialty;
  address?: AddressWithFull;
}

export interface ServiceInformation {
  serviceType?: string;
  placeOfService?: string;
  diagnoses?: Diagnosis[];
  procedures?: Procedure[];
}

export type ServiceInformationYup = {
  serviceType?: string;
  placeOfService?: string;
  diagnoses?: Diagnosis[];
  procedures?: Procedure[];
};

export enum QuantityType {
  UNITS = 0,
  VISITS = 1,
}

export interface Procedure {
  cpt?: Cpt;
  date: Date;
  quantity?: {
    type: QuantityType;
    value: number;
  };
  key?: number;
}

export interface Diagnosis {
  code: string;
  description: string;
}

export interface ProcedureResponse {
  status: string;
  statusCode: string;
  serviceQuantity: string;
  quantityType: string;
  cpt: { code: string; description: string };
  statusReasons: ProcedureStatusReasonsResponse[];
  notes: ProcedureNoteResponse[];
  startDate: string;
  expirationDate: string;
}

export interface ProcedureStatusReasonsResponse {
  value: string;
}

export interface ProcedureNoteResponse {
  message: string;
}

export interface Cpt {
  code: string;
  description: string;
}

export type CptOption = {
  value: {
    code: string;
    description: string;
  };
  label: string;
};

export interface ReferralRequestResponse {
  status: ReferralRequestStatus;
  procedures?: ProcedureResponse[];
  reason?: string;
  certificationNumber?: string;
  certificationEffectiveDate?: string;
  certificationExpirationDate?: string;
  fromDate?: string;
  toDate?: string;
  memberInformation?: MemberInformationResponse;
  serviceInformation?: ServiceInformationResponse;
}

interface MemberInformationResponse {
  memberId?: string;
  subscriberName?: {
    firstName: string;
    lastName: string;
  };
  subscriberRelationship?: string;
}

interface ServiceInformationResponse {
  quantityType: string;
  serviceLevel: string;
  quantity: string;
  fromDate: string;
  toDate: string;
}

export enum UpdateCodeStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  NOT_SUPPORTED = 'NOT_SUPPORTED',
}

export type ReferralRequestFormSubmission = {
  success: boolean;
  response?: ReferralRequestResponse;
  updateCodeStatus: UpdateCodeStatus;
};

export interface ProviderInput {
  npi: string;
  firstName?: string;
  lastName: string;
  vimSpecialtyId: string;
  location: ProviderLocationInput;
}

interface ProcedureInput {
  cpt: Cpt;
  date: Date;
  quantity: number;
  quantityTypeCode: string;
}

interface ProviderLocationInput {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zip: string;
}

export interface RequestReferralInput {
  referringProvider: ProviderInput;
  targetProvider: ProviderInput;
  serviceTypeCode: string;
  placeOfServiceCode: string;
  diagnosis: string[];
  procedures: ProcedureInput[];
  memberToken?: string;
  anonymizedPatient?: string;
}

export interface ReferralRequestExportContext {
  patient?: Patient;
  requestingProvider?: RequestingProvider;
  targetProvider?: WriteBackProvider;
  serviceInformation?: ServiceInformation;
  response?: ReferralRequestResponse;
  userConfig?: UserConfig;
}

export interface PdfProcedure {
  code: string;
  statusBadge: string;
  quantity: string;
  message: string;
}

export interface PdfRequestingProvider {
  name: string;
  npi?: string;
  speciality?: string;
  address?: string;
  phoneNumber?: string;
  faxNumber?: string;
}

export interface PdfRenderingProvider {
  name: string;
  npi?: string;
  specialties?: string[];
  address?: string;
  phoneNumber?: string;
  faxNumber?: string;
}

export interface ReferralRequestPDFInput {
  clinicName: string;
  exportingUser: string;
  dateOfExporting: string;
  datasourceLogo?: string;
  patient: {
    name: string;
    dateOfBirth: string;
    age: number;
  };
  status: {
    certificationNumber?: string;
    badge?: string;
    statusReason?: string;
    certificationEffectiveDate: string | null;
    certificationExpirationDate: string | null;
  };
  memberInformation: {
    name: string;
    address?: string;
    subscriberName?: string;
    subscriberRelationship?: string;
    memberId?: string;
  };
  serviceInformation: {
    placeOfService?: string;
    serviceType?: string;
    serviceLevel?: string;
    fromDate: string | null;
    toDate: string | null;
    quantity?: string;
  };
  diagnosisCodes?: string[];
  procedures?: PdfProcedure[];
  requestingProvider: PdfRequestingProvider;
  renderingProvider: PdfRenderingProvider;
}
